@font-face {
  font-family: "Neue Haas Grotesk Text Pro";
  src: url("./assets/font/NHaasGroteskTXPro-Bd.eot");
  src: url("./assets/font/NHaasGroteskTXPro-Bd.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/NHaasGroteskTXPro-Bd.woff2") format("woff2"),
    url("./assets/font/NHaasGroteskTXPro-Bd.woff") format("woff"),
    url("./assets/font/NHaasGroteskTXPro-Bd.ttf") format("truetype"),
    url("./assets/font/NHaasGroteskTXPro-Bd.svg#NHaasGroteskTXPro-Bd")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Grotesk Text Pro";
  src: url("./assets/font/NeueHaasGroteskTextPro.eot");
  src: url("./assets/font/NeueHaasGroteskTextPro.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/NeueHaasGroteskTextPro.woff2") format("woff2"),
    url("./assets/font/NeueHaasGroteskTextPro.woff") format("woff"),
    url("./assets/font/NeueHaasGroteskTextPro.ttf") format("truetype"),
    url("./assets/font/NeueHaasGroteskTextPro.svg#NeueHaasGroteskTextPro")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Grotesk Text Pro";
  src: url("./assets/font/NeueHaasGroteskTextPro-Medium.eot");
  src: url("./assets/font/NeueHaasGroteskTextPro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/NeueHaasGroteskTextPro-Medium.woff2") format("woff2"),
    url("./assets/font/NeueHaasGroteskTextPro-Medium.woff") format("woff"),
    url("./assets/font/NeueHaasGroteskTextPro-Medium.ttf") format("truetype"),
    url("./assets/font/NeueHaasGroteskTextPro-Medium.svg#NeueHaasGroteskTextPro-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Neue Haas Grotesk Text Pro";
  font-weight: 400;
  background-color: #130042;
  color: #ffffff;
  overflow-x: hidden;
}

a {
  color: #00ffcc;
  text-decoration: none;
}

a:hover {
  color: #ff437f;
  text-decoration: underline;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background: radial-gradient(
    65.27% 114.93% at 49.85% -15.06%,
    rgb(60, 40, 125) 0%,
    rgb(19, 0, 66) 100%
  );
}
.connect-wallet-popup {
  padding-bottom: 30px;
}
.connect-wallet-popup .btn-close {
  width: 100%;
  opacity: 1;
  background: none;
}

/* Custom Radio Buttons */

:root {
  --primary-color: #e397f3;

  --text-color: #ffffff;
  --text-color-hover: #ffffff;
  --text-color-checked: #ffffff;

  --radio-icon-field: 48px;
  --radio-icon-size: 20px;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.option input[type="radio"] ~ label {
  --radio-icon-border: 2px;
  --radio-icon-inner: calc(
    var(--radio-icon-size) - 4 * var(--radio-icon-border)
  );
  --radio-target-field: calc(
    (var(--radio-icon-field) - var(--radio-icon-size)) / 2
  );

  cursor: pointer;
  position: relative;
  padding-left: 32px;
  font-size: 18px;
  line-height: 1.4;
}

.option input[type="radio"] ~ label::before,
.option input[type="radio"] ~ label::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.option input[type="radio"] ~ label::before {
  left: 0;
  width: var(--radio-icon-size);
  height: var(--radio-icon-size);
  border: var(--radio-icon-border) solid var(--primary-color);
}

.option input[type="radio"] ~ label:hover::before {
  box-shadow: 0px 0px 0px var(--radio-target-field) rgba(0, 0, 0, 0.1);
}

.option input[type="radio"] ~ label::after {
  left: calc(var(--radio-icon-border) * 2);
  width: var(--radio-icon-inner);
  height: var(--radio-icon-inner);
  opacity: 0;
  transform: translateY(-50%) scale(0);
  background-color: var(--primary-color);
}

.option input[type="radio"]:checked + label::after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

.option input[type="radio"]:hover ~ label {
  color: var(--text-color-hover);
}

.option input[type="radio"]:checked ~ label {
  color: var(--text-color-checked);
}
.flex-option {
  display: flex;
  margin-bottom: 24px;
}
.flex-option .option {
  margin-right: 36px;
}
img.brand_logo {
  width: 201px;
  height: 65px;
}

nav {
  position: relative;
}

.navbar-toggler:focus {
  box-shadow: none;
}

nav::before {
  content: "";
  background-color: rgb(100 8 194 / 50%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: -0px;
  filter: blur(184px);
  right: 0;
  z-index: -1;
}

.navbar-brand {
  outline: 0;
}
.default-address {
  background: rgba(105, 13, 196, 0.1);
  padding: 12px;
  border-radius: 10px;
  color: #ffffff;
}
.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #130042;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.nav-icons {
  display: flex;
  align-items: center;
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.main_navbar {
  z-index: 10;
}

.offcanvas.offcanvas-end {
  background: #130042;
}

.modal-header .btn-close {
  filter: invert(1);
}

.navbar_right {
  text-align: center;
}

.btn_mode {
  display: flex;
  gap: 40px;
  align-items: center;
}

.modechange {
  background-color: #110035;
  border-radius: 50px;
  padding: 10px 20px;
}
.modechange img {
  cursor: pointer;
}
.walletdrop.show {
  background: linear-gradient(331deg, #6408c2 0%, #7016c7 44.95%, #e498f4 100%)
      padding-box,
    linear-gradient(
        190deg,
        #e0bbf3 37.28%,
        #e0bbf3 23.78%,
        #670cc3 129.35%,
        #6b1075 87.59%
      )
      border-box !important;
}
.warning-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.warning-flex a {
  color: #6408c2;
}
.walletdrop .dropdown-item {
  color: white;
}
.nav-icons {
  display: flex;
  align-items: center;

  gap: 20px;
}

.modechange1 {
  background-color: #110035;
  border-radius: 50px;
  padding: 10px 20px;
}

/* Connect Wallet */
.primary_modal .modal-content {
  background: radial-gradient(
    65.27% 114.93% at 49.85% -15.06%,
    rgb(60, 40, 125) 0%,
    rgb(19, 0, 66) 100%
  );
  border: 1px solid #3c287d;
  color: #ffffff;
}

.primary_modal .modal-header {
  border-bottom: 0px solid #2a2a2a;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 1px solid #3c287d;
  background-color: transparent;
  color: #ffffff;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.5s;
}

.connect_wallet_button:hover {
  border: 1px solid #3c287d;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #e397f3;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.modal-header .btn-close {
  width: 32px;
  height: 32px;
  color: #fff;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
}

.modal-header .btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}

.h2tag {
  color: #e397f3;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  width: fit-content;
}

.para {
  color: #fff;
  font-weight: 400;
  font-size: 23px;
}

.primary_btn {
  border-radius: 100px;
  background: linear-gradient(331deg, #6408c2 0%, #7016c7 44.95%, #e498f4 100%)
      padding-box,
    linear-gradient(
        190deg,
        #e0bbf3 37.28%,
        #e0bbf3 23.78%,
        #670cc3 129.35%,
        #6b1075 87.59%
      )
      border-box;
  border: 1px solid transparent;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  padding: 17px 20px;
  box-shadow: none !important;

  position: relative;
  overflow: hidden;
}

.primary_btn:hover {
  background: #e397f3;
  border: 1px solid #e0bbf3;
  color: White;
}

.primary_btn:hover .icon {
  color: #e0bbf3;
}

.text2 {
  padding-left: 10px;
}

/* .primary_btn .icon {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  transition: 1s;
} */
.text1 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  right: 95%;
  gap: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  /* background: linear-gradient(270deg, #000 0%, #111 44.95%, #7d5a00 100%)
      padding-box,
    linear-gradient(0deg, #11111100 0%, #635849 127.95%, #605a4a 100%)
      border-box; */
  /* border: 1px solid transparent; */
  transition: 1s;
  white-space: nowrap;
  width: 100%;
}

.primary_btn:hover .icon {
  left: calc(100% - 43px);
}

.primary_btn:hover .text1 {
  right: 0;
  padding-left: 20px;
  width: 100%;
}

.page_header {
  position: relative;
  z-index: 1;
}

.page_header::before {
  content: "";
  background: url(./assets/images/ban_before.png) no-repeat center;
  background-size: 100%;
  width: 100%;
  height: 720px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  margin: auto;
  z-index: 0;
}

.page_header .side img {
  position: relative;
  top: -91px;
}

.side {
  position: relative;
}

.page_header .side::before {
  content: "";
  background-color: rgb(100 8 194 / 50%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  filter: blur(484px);
  right: 0;
  z-index: -1;
}

.page_header::after {
  /* content: "";
  background: url("./assets/images/banshade.png") no-repeat center;
  background-size: 100%;
  width: 100%;
  height: 845px;
  position: absolute;
  left: -0px;
  right: 0;
  top: -180px;
  bottom: 0;
  margin: auto;
  z-index: -1;
  filter: blur(10px); */
}

.page_header .img_box {
  position: relative;
  z-index: -1;
  margin-top: 100px;
}

/* .page_header .img_box::before {
  content: "";
  position: absolute;
  background: radial-gradient(70.39% 37.91% at 64.65% 48.97%, #271c00 0%, #000 120%);
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  right: -100px;
  top: -180px;
  z-index: -1;
  filter: blur(21px);
} */
.page_header h1 {
  color: #fff;
  font-family: Neue Haas Grotesk Text Pro;
  font-size: 71px;
  font-style: normal;
  font-weight: normal;
  line-height: 70px;
  margin-bottom: 30px;
}

.page_header h1 span {
  color: #e397f3;
  font-family: Neue Haas Grotesk Text Pro;
  font-size: 71px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
}

.head1 {
  position: relative;
}

.head1::before {
  content: "";
  background-color: rgb(100 8 194 / 50%);
  position: absolute;
  top: 50px;
  bottom: 0;
  left: -84px;
  filter: blur(184px);
  right: 0;
  z-index: -1;

  border-radius: 50%;
}

.page_header .para {
  margin-bottom: 30px;
}

.page_header .para span {
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}

.page_header .col-lg-5 {
  z-index: 5;
}

.main_wrapper {
  z-index: 3;
  position: relative;
}

.g_bg {
  background: url("./assets/images/g_bg.png") no-repeat top center;
  background-size: 100% 99%;
}

.ban2-sec {
  position: relative;
}

.ban2-sec::before {
  content: "";
  position: absolute;
  background: url(./assets/images/sec2-before.png);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.ban2-sec h6 {
  font-weight: 400;
  font-size: 24px;
  color: #fff;
  margin-bottom: 0px;
  padding-bottom: 7px;
}

.ban2-sec h2 {
  font-weight: 700;
  font-size: 64px;
  color: #e397f3;
  margin-bottom: 0px;
  padding-bottom: 30px;
}

.ban2-sec p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 0px;
}

.faq .accordion {
  max-width: 85%;
  margin: 0 auto;
}

.sec2-right {
  padding-bottom: 70px;
}

.sec2-card {
  background: #130042;
  background: linear-gradient(#130042, #130042) padding-box,
    linear-gradient(
        90deg,
        #3c287d00 0%,
        #3c287d 20.78%,
        #3c287d00 19.35%,
        #3c287d 0%
      )
      border-box;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid transparent;
}

.sec2-card-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.sec2-card-content p {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #e397f3 !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
}

.sec2-left img {
  padding-bottom: 40px;
  margin-top: 10px;
}

.sec2-left {
  position: relative;
}

.sec2-left::before {
  content: "";
  background: rgb(101 8 194 / 100%);
  right: 0;
  left: 0;
  border-radius: 50%;
  filter: blur(201px);
  bottom: 0;
  z-index: -1;
  top: -142px;
  position: absolute;
}

.sec2-card-content img {
  padding-bottom: 0px;
  margin-top: 0px;
}

.sec2-card p {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  margin-bottom: 0px;
  padding-top: 20px;
}

.sec3-bg {
  background: url(./assets/images/sec3-banner.png) no-repeat center;
  background-color: #250f54;
  background-size: cover;
  width: 100%;
  margin-top: 90px;
  padding: 50px;
  border-radius: 25px;
}

.ban3-sec {
  position: relative;
}

.ban3-sec::before {
  content: "";
  background: url(./assets/images/sec3-before.png);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.sec3-bg p {
  font-weight: 400;
  font-size: 24px;
  color: #fff;
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 7px;
}

.sec3-bg h2 {
  font-weight: 700;
  font-size: 64px;
  color: #e397f3;
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 70px;
}

.sec3-content {
  display: flex;
}

.black-box {
  background: linear-gradient(#130042, #130042) padding-box,
    linear-gradient(
        90deg,
        #3c287d00 0%,
        #3c287d 20.78%,
        #3c287d00 19.35%,
        #3c287d 0%
      )
      border-box;
  padding: 50px;
  max-width: 80%;
  border-radius: 20px;
}

.black-box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-items {
  align-items: center;
}

.black-box1 p {
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: start;
  font-size: 18px;
  font-weight: 700;
}

.black-box1 h2 {
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: start;
  font-size: 48px;
  font-weight: 700;
}

.black-box1 h2 {
  margin-bottom: 0;
  padding-bottom: 0;
}

.faq {
  margin-top: 10%;
  margin-bottom: 40px;
}

.faq .accordion-item {
  box-shadow: none !important;
  border: 0;
  border-bottom: 1px solid #3c287d;
  background: transparent;
  border-radius: 0;
  padding: 20px 0;
  position: relative;
}

.faq .accordion-item:nth-last-child(1) {
  border: 0;
}

/* .accordion .accordion-item {
  background: linear-gradient(#130042, #130042) padding-box,
    linear-gradient(
        12deg,
        #3c287d 37.28%,
        #3c287d 20.78%,
        #3c287d 19.35%,
        #3c287d 0%
      )
      border-box !important;
  border-radius: 25px;
  border: 1px solid transparent !important;
  padding: 20px;
}
.accordion .accordion-item:not(.collapsed) {
  border: none !important;
  border-bottom: 1px solid #3c287d !important;
}
.accordion .accordion-item .accordion-button:not(.collapsed) {
  border: none !important;
} */
.accordion-item[aria-expanded="true"] {
  /* border: 1px solid #000 !important;  */
  background: linear-gradient(350deg, #130042, #3c287da9) padding-box,
    linear-gradient(
        90deg,
        #4b4198 30%,
        #4b4198 20.78%,
        #4b4198 19.35%,
        #4b4198 0%
      )
      border-box !important;
  border-radius: 20px;
  padding: 20px;
}

.faq .accordion-item button {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  background: transparent;
  box-shadow: none !important;
  /* padding: 20px; */
}

.faq .para {
  font-weight: 400;
  font-size: 16px;

  color: #fcfbff;
  margin-bottom: 0;
}

.faq .accordion-body {
  padding: 15px 20px;
}

.faq .accordion-button:not(.collapsed) {
  color: #e397f3;
}

.faq .accordion-button:not(.collapsed)::after {
  /* background: url("./assets/images/down_arw.png") no-repeat center; */
  width: 10px;
  height: 7px;
  transition: 0.5s;
  background: url("./assets/images/down_arw.png") no-repeat center;
}

.faq .accordion-button.collapsed::after {
  rotate: 270deg !important;
  background: url("./assets/images/down_arw.png") no-repeat center;
  width: 10px;
  height: 7px;
  transition: 0.5s;
}

.faq .head h2 {
  font-weight: 700;
  font-size: 64px;
  text-align: center;
  color: #e397f3;
}

.faq .head p {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.04em;
  color: #fcfbff;
}

.footer {
  background: #0d002c;
  padding: 20px 0;
  /* margin-top: 90px; */
}

.footer .para {
  background: linear-gradient(
    47deg,
    #fff7a1 8.3%,
    #e4a837 32.68%,
    #fdf294 60.44%,
    #e4a837 78.94%,
    #fff7a1 92.39%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
  word-break: break-word;
}

.footer_panel_right1 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: end;
}

.footer_panel_right {
  padding: 20px;
}

.ptag1 {
  font-weight: 400;
  font-size: 18px;
  max-width: 18%;
  color: #8ea5c8;
}

.ptag3 {
  font-weight: 400;
  font-size: 16px;

  color: #8ea5c8;
}

.ptag2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 167%;
  color: #f5f7f8;
}

.footer_panel_right-ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
  padding-left: 0px;
}

.footer_panel_right-ul li a {
  background: #010026;
  padding: 10px;
  color: #814596 !important;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
}

.footer_panel_right-ul li a:hover {
  background: linear-gradient(
    to right,
    #e498f4 7%,
    #e094f3 24%,
    #7016c7 55%,
    #6408c2 88%
  );
  color: white !important;
}

.footer p {
  margin-bottom: 0;
}

.footer_social_links {
  margin-left: auto;
  list-style-type: none;
  width: fit-content;
}

.footer_social_links img {
  border-radius: 50%;
}

.joinnow {
  min-height: 100vh;
  padding-top: 90px;
  position: relative;
  margin-bottom: 40px;
}

/* .joinnow::after {
  content: "";
  background: url("./assets/images/banshade.png") no-repeat center;
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: -0px;
  right: 0;
  top: -180px;
  bottom: 0;
  margin: auto;
  z-index: -1;
  filter: blur(100px);
} */

.joinnow .box {
  transition: 0.25s;
  padding: 50px 30px;
  border-radius: 15px;
  position: relative;
  border: 1px solid #3c287d;
  background: radial-gradient(
    65.27% 114.93% at 49.85% -15.06%,
    rgb(60, 40, 125) 0%,
    rgb(19, 0, 66) 100%
  );
  border-bottom: 1px solid #3c287d;
  border-right: 1px solid #3c287d;
  border-top: 1px solid #3c287d;
}

/* .joinnow .box::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -8px;
  width: 100%;
  height: 15px;
  border-radius: 627px;
  background: radial-gradient(
    11.29% 41.47% at 48.09% 51.85%,
    #fdf294 30%,
    #e4a837 30.73%,
    rgba(0, 0, 0, 0) 150%
  );
  filter: blur(2.5px);
} */

.joinnow .box input {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid transparent;
  border-right: 1px solid transparent !important;
  padding: 8px;
  box-shadow: none !important;
  color: #fff;
}
.joinnow .box input::placeholder {
  color: #fff;
}
.joinnow .box .inbox {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.03);
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.dashboard .box {
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(255, 255, 255, 0.03);
}

.dashboard .box .inbox {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.03);
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 1199px) {
  .total .tot_box {
    padding: 55px;
  }

  .rewards .tab-container {
    gap: 12px;
  }

  .rewards .tab-container .tab {
    padding: 10px 15px;
  }
}

@media (max-width: 991px) {
  .page_header {
    padding-top: 70px;
  }
  .light .offcanvas-body {
    background: white;
  }
  .light .offcanvas-header {
    background: white;
  }
  .total .right_box {
    padding-left: 0;
    padding-top: 15px;
  }

  .total .tot_box h3 {
    font-size: 40px;
  }

  .features .col-lg-4:nth-child(2) .box::before,
  .features .col-lg-4:nth-child(2) .box::after,
  .features .col-lg-4:nth-child(3) .box::before,
  .features .col-lg-4:nth-child(3) .box::after {
    display: none;
  }

  .features .col-lg-4:nth-child(2) .box {
    padding: 20px 0;
  }

  .features .col-lg-4:nth-child(3) .box {
    padding-left: 0;
  }

  .rewards .tab-container {
    flex-wrap: wrap;
  }

  .rewards .tab-content ul {
    font-size: 18px;
  }

  .g_bg {
    background-size: unset;
  }
  .dashboard_info_details {
    flex-direction: column;
  }
  .dashboard_info h3 {
    font-size: 26px;
  }
  .dashborad_content_info {
    flex-direction: row !important;
  }
  .dashboard_info.dashboard_info_details {
    align-items: center !important;
  }
  .dashboard_info h3 {
    text-align: center;
    font-size: 21px !important;
  }
  .dashboard_info p {
    text-align: center;
    font-size: 14px !important;
  }
  .join-now-sides {
    flex-direction: column;
  }
  .left-side-joinnow {
    width: 100% !important;
  }
  .right-side-joinnow {
    width: 100% !important;
    padding: 0 !important;
  }
}

@media (max-width: 767px) {
  .h2tag {
    font-size: 30px;
    line-height: unset;
  }

  .platform_activity .activity_box {
    padding: 17px;
  }

  .platform_activity .flxbox {
    flex-wrap: wrap;
  }

  .platform_activity .flxbox div {
    margin: 5px;
    margin-right: 15px;
    margin-left: 0;
  }

  .platform_activity .activity_box_flx {
    flex-direction: column;
    align-items: baseline;
    padding: 20px;
  }

  .platform_activity .rightbtn p {
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-top: 17px;
  }

  .rewards .box,
  .total .tot_box {
    padding: 25px;
  }

  .footer_social_links {
    margin-left: 0;
    padding: 0;
    padding-top: 15px;
  }

  .total .img_box {
    min-width: 70px;
    height: 70px;
  }

  .total .img_box img {
    width: 45px;
  }

  .total .tot_box h3,
  .page_header h1 {
    font-size: 32px;
    line-height: unset;
  }
  .dashboard_info.dashboard_info_details {
    flex-direction: row;
  }
  .dashborad_content_info {
    flex-direction: column !important;
  }
}

.coin_wrapper {
  position: relative;
  z-index: 10;
  font-size: 277px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#000, #000);
  margin: auto;
  box-shadow: 0px 0px 50px #ffc861;

  position: absolute;
  left: 0;
  right: 0;
}

.coin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-animation: rotate 3s infinite linear;
  animation: rotate 3s infinite linear;
  transform-style: preserve-3d;
  z-index: -1;
}

.coin .side,
.coin:before,
.coin:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  background: #000;
  border-radius: 50%;
  right: -120px;
  right: -0.396em;
  text-align: center;
  line-height: 1;
  color: #000;
  text-shadow: 1px 2px 0 #000, -1px -2px 0 #000;
  transform: rotateY(-90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.coin .tails,
.coin:after {
  left: -120px;
  left: -0.396em;
  transform: rotateY(90deg);
}

.coin:before,
.coin:after {
  background: linear-gradient(#000, #000);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.coin:after {
  transform: rotateY(-90deg);
}

.imageCoin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-height: 1em;
  background-color: #000;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotateY(360deg);
  }
}

.dropdown-menu.show {
  display: block;
  background: #1e1e1e;
  color: #fff;
  font-size: 14px;
  padding-left: 16px;
  padding-top: 20px;
  padding: 14px;
}

.dropdown .dropdown-menu p {
  font-size: 16px;
  font-weight: 400;
  color: #d6dadf;
}

.tabledeatils {
  margin-top: 30px;
}

.tabledeatils h6 {
  font-size: 20px;
  font-weight: 600;
  color: #f8dc52;
  margin-bottom: 10px;
}

.tabledeatils .rdt_TableHeadRow {
  background: #151515 !important;
  color: #d1d1d1;
  font-weight: 600;
  font-size: 18px;
}

.tabledeatils .rdt_TableRow {
  background-color: #0d0d0d;
  color: #fff;
}

.tabledeatils .rdt_Pagination {
  background: #0f0f0f;
  color: #fff;
}

.tabledeatils .rdt_Pagination button {
  fill: rgb(255 255 255 / 54%);
}

.tabledeatils .rdt_Pagination button:disabled {
  opacity: 0.5;
}

.tabledeatils .rdt_Pagination select {
  background: #0f0f0f;
}

.tabledeatils .rdt_Pagination option {
  background: #0f0f0f;
  color: #fff;
}

.tabledeatils .rdt_Pagination option[selected] {
  background: #0f0f0f !important;
}

/* #main {
  display: block;
  width: 277px; 
 height: 277px;
  top: 10px;
  left: 10px;
  -webkit-transform-style: preserve-3d;
  -webkit-perspective: 1000px;
 animation:turn 2.5s infinite linear;
 margin:auto;
 top:12%;
 position: absolute; 
    left: 0;
    right: 0;
}

.coin {
  background: url("./assets/images/ban_pic.png");
  border-radius: 50%;
  position: relative;
  -webkit-transform-style: preserve-3d;
  animation: spin 3s linear infinite;
  width: 277px;
  height: 277px;
  transform: rotateY(154deg); 
  
  box-shadow: 0px 0px 50px #FFC861;
}
@keyframes spin {
  0% {
    transform: rotateY(0deg);
 }
  100% {
    transform: rotateY(360deg);
 }
}
.coin:before {
  background-color: black;
  position: absolute;
  border-radius: 50%;
  content: "";
  -webkit-transform: translateZ(-0.75em);
  width: 277px;
  height: 277px;
}
.coin:after {
  background-color: black;
  content: "";
  left: 50%;
  position: absolute;
  height: 276px;
  width: 0.7em;
  z-index: -10;
  -webkit-transform: rotateY(-90deg);
  -webkit-transform-origin: 100% 50%;
  top: 1px;
}
.tails {
  background-color: black;
  position: absolute;
  border-radius: 50%;
  content: "";
  -webkit-transform: translateZ(-0.005em);
  border: 1px solid #FF9900;
  width: 277px;
  height: 277px;
}
.heads {
  position: absolute;
  border-radius: 50%;
  content: "";
  -webkit-transform: translateZ(-0.755em);
  border: 1px solid #FF9900;
  background: url("./assets/images/ban_pic.png");
  width: 277px;
  height: 277px;
  transform: translateZ(-12.4px) rotateY(180deg);
} */
/* ---------------------------- */
@media (max-width: 1399px) {
  .page_header h1 span {
    font-size: 52px;
  }

  .page_header h1 {
    font-size: 52px;
  }

  .ban2img {
    margin-bottom: 50px;
  }
}

@media (max-width: 1199px) {
  .page_header h1 span {
    font-size: 42px;
  }

  .page_header h1 {
    font-size: 42px;
  }

  .para {
    font-size: 17px;
  }

  .ban2-sec h2 {
    font-size: 54px;
  }

  .ban2-sec p {
    font-size: 16px;
  }

  .faq .head h2 {
    font-size: 38px;
  }

  .sec2-left img {
    padding-bottom: 24px;
  }

  .sec2-card-content img {
    padding-bottom: 0;
  }

  .black-box {
    max-width: 91%;
  }

  .sec3-bg p {
    font-size: 18px;
  }

  .sec3-bg h2 {
    font-size: 38px;
  }

  .footer_panel_right1 {
    flex-direction: column;
    align-items: center;
  }

  .footer_panel_right1 p {
    padding-bottom: 20px;
  }

  .footer_panel_right1 div {
    text-align: center;
  }

  .footer_panel_right .logo-img {
    display: flex;
    justify-content: center;
  }

  .ptag1 {
    max-width: 100%;
  }

  .ban2img {
    margin-bottom: 50px;
  }
}

@media (min-width: 991px) {
  .modechange1 {
    display: none;
  }
}

@media (max-width: 991px) {
  .modechange {
    display: none;
  }
  .btn_mode {
    flex-direction: column;
  }
  .page_header {
    padding-top: 70px;
    text-align: center;
  }

  .page_header .side img {
    top: -128px;
  }

  .sec2-right {
    text-align: center;
  }

  .sec2-left {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 30px;
  }

  /* .sec2-left img {
  padding-bottom: 40px;

} */
  .ban2-sec {
    position: relative;
    top: -123px;
  }

  .ban3-sec {
    position: relative;
    top: -119px;
  }

  .black-box {
    max-width: 100%;
  }

  .black-box {
    padding-top: 20px;
  }

  .faq {
    margin-top: 0px;
  }

  .sec3-bg {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .page_header h1 {
    font-size: 32px;
    line-height: 38px;
  }

  .page_header h1 span {
    font-size: 32px;
    line-height: 38px;
  }

  .faq .accordion {
    max-width: 100%;
  }
}

@media (max-width: 575px) {
  img.brand_logo {
    width: 150px;
    height: 50px;
  }

  .ban2-sec h2 {
    font-size: 34px;
  }

  .box-items {
    gap: 30px;
  }

  .black-box1 h2 {
    font-size: 28px;
  }

  .sec3-bg p {
    font-size: 16px;
  }

  .footer_panel_right1 p {
    word-break: break-all;
    text-align: center;
  }

  .footer_panel_right-ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .black-box1 {
    flex-direction: column;
    gap: 20px;
  }

  .black-box1 p {
    text-align: center;
  }

  .sec3-bg h2 {
    font-size: 28px;
    padding-bottom: 20px;
  }
}

/* DashBoard */
.register_time {
  border: 1px solid #3c287d;
  border-radius: 50px;
  padding: 15px 20px;
  background: #25125c;
}

.dashboard_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard_head h3 {
  font-weight: 700;
  font-size: 36px;
  line-height: 167%;
  letter-spacing: -0.02em;
  color: #e397f3;
}

.register_time {
  display: flex;
  align-items: center;
  gap: 10px;
}

.register_time p {
  font-weight: 400;
  font-size: 16px;
  color: #a0a0a0;
  margin-bottom: 0px;
}

.register_time p span {
  color: #fff;
}

.dashborad_content {
  background: radial-gradient(
    65.27% 114.93% at 49.85% -15.06%,
    rgb(60, 40, 125) 0%,
    rgb(19, 0, 66) 100%
  );
  border-bottom: 1px solid #3c287d;
  border-right: 1px solid #3c287d;
  border-top: 1px solid #3c287d;
  border-radius: 25px;
  padding: 0px 30px 10px 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .dashboard_text{
  display: block;
  gap: 10px;
} */
.dashboard_info {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  padding: 10px;
}
.dashboard_info {
  padding-right: 60px;
  border-right: 1px solid #4f3080;
}
.dashborad_content .dashboard_info:nth-child(4) {
  border-left: 1px solid #4f3080;
  padding-left: 60px;
}
.dashborad_content .dashboard_info:nth-child(4) {
  border-right: 1px solid transparent;
}
.dashborad_content .dashboard_info:nth-child(3) {
  margin-top: 47px;
  border-right: none;
}
.dashborad_content .dashboard_info:nth-child(3) .dashboard_text {
  border-right: none;
}
/* .dashboard_info::after{
  content: "";
border-right: 1px solid red !important;

} */
.dashboard_info h3 {
  font-weight: 500;
  font-size: 32px;
  text-transform: capitalize;
  color: #e397f3;
  /* margin-bottom: 0px; */
}

.dashboard_info h3 span {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #fcfbff;
}

.dashboard_info p {
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #fcfbff;
  margin-bottom: 0px;
}
.dashborad_content .dashboard_text .primary_btn {
  padding: 5px 20px;
}
.dashboard_info img {
  background: radial-gradient(
    135% 135% at 51.4% -7%,
    rgb(100, 8, 194) 0%,
    rgb(214, 137, 239) 100%
  );
  padding: 18px;
  border-radius: 50px;
  box-shadow: 0 16px 28px 0 rgba(223, 143, 240, 0.2);
}

.rank_board {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: radial-gradient(
    65.27% 114.93% at 49.85% -15.06%,
    rgb(60, 40, 125) 0%,
    rgb(19, 0, 66) 100%
  );
  border-bottom: 1px solid #3c287d;
  border-right: 1px solid #3c287d;
  border-top: 1px solid #3c287d;
  border-radius: 30px;
  padding: 16px 40px;
}

.rank_board h6 {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  margin-bottom: 0px;
}
.rank_board h4 {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  margin-bottom: 0px;
}

.copy-img {
  filter: brightness(0) invert(1);
}
.income_info {
  background: radial-gradient(
    65.27% 114.93% at 49.85% -15.06%,
    rgb(60, 40, 125) 0%,
    rgb(19, 0, 66) 100%
  );
  border-bottom: 1px solid #3c287d;
  border-right: 1px solid #3c287d;
  border-top: 1px solid #3c287d;
  border-radius: 30px;
  padding: 14px 22px;
  box-shadow: 0 0px 1px 0 rgba(223, 143, 240, 0.2);
}

.income_info h3 {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: -0.02em;
  color: #e397f3;
  margin-bottom: 10px;
}

.income_info .dashboard_info {
  background: radial-gradient(
    65.27% 114.93% at 49.85% -15.06%,
    rgb(60, 40, 125) 0%,
    rgb(19, 0, 66) 100%
  );
  border-bottom: 1px solid #3c287d;
  border-right: 1px solid #3c287d;
  border-top: 1px solid #3c287d;
  border-radius: 15px;
  padding: 15px 22px;
  margin: 20px 0px;
}
.missed_income.income_info .dashboard_info {
  border-bottom: 1px solid #f8c808;
  border-right: 1px solid #f8c808;
  border-top: 1px solid #f8c809;
  border-radius: 15px;
  background: #f7d23f;
}
.withdraw_info .dashboard_info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.income_info .dashboard_text h3 {
  font-weight: 500;
  font-size: 20px;
  color: #e397f3;
  text-align: center;
}
.missed_income.income_info .dashboard_text h3 {
  color: #130042;
}
.income_info .dashboard_text h3 span {
  font-weight: 400;
  font-size: 18px;
  color: #fff;
}
.missed_income.income_info .dashboard_text h3 span {
  color: #130042;
}
.income_info .dashboard_text p {
  font-weight: 400;
  font-size: 13px;
  /* line-height: 200%; */
  color: #fff;
}
.missed_income.income_info .dashboard_text p {
  color: #6408c2;
}
.package_info {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.rdt_Table > div {
  background: transparent !important;
  border-radius: 0;
  color: #ffffff;
}
.rdt_Pagination {
  color: #e397f3 !important;
}
.rdt_Pagination button {
  color: #e397f3 !important;
  fill: #e397f3 !important;
}
.package_info .dashboard_info {
  margin: 0px;
  position: relative;
  border-bottom: 1px solid #3c287d;
  border-right: 1px solid #3c287d;
  border-top: 1px solid #3c287d;
  border-radius: 25px;
  padding: 32px 87px;
  background: radial-gradient(
    65.27% 114.93% at 49.85% -15.06%,
    rgb(60, 40, 125) 0%,
    rgb(19, 0, 66) 100%
  );
}
.package_info .joined {
  position: relative;
}
.package_info .joined::after {
  content: "Joined";
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #130042;
  padding: 2px 36px;
  border-radius: 0 0px 25px 25px;
  display: inline-block;
  transform: rotate(-90deg);
  position: absolute;
  right: -56px;
  top: 54px;
  /* box-shadow: 0 16px 28px 0 rgba(223, 143, 240, 0.2); */
  background: radial-gradient(
    135% 135% at 51.4% -7%,
    rgb(100, 8, 194) 0%,
    rgb(214, 137, 239) 100%
  );
}

.package_info {
  position: relative;
}

.package_info .dashboard_info h3 {
  font-weight: 500;
  font-size: 32px;
  color: #e397f3;
  text-align: center;
  margin-bottom: 15px;
}

.package_info .dashboard_info p {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin-bottom: 0px;
}
.package_info .re_join .dashboard_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.package_info .re_join {
  padding: 12px 85px !important ;
  background: #281959;
  border-radius: 25px;
}
/* .package_info .join-Now{
  padding: 12px 85px !important ;
} */
.package_info .re_join h3 {
  margin-bottom: 5px !important;
}
.package_info .re_join p {
  margin-bottom: 5px !important;
}
.package_info .re_join .primary_btn {
  padding: 8px 30px;
}
.package_info .join-Now .primary_btn {
  padding: 8px 30px;
}
.package_info .disabled {
  opacity: 0.5;
}
.income_info .form-control {
  padding: 12px 18px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  color: #fff;
}
.income_info .form-control:focus {
  box-shadow: none;
  border: 1px solid transparent;
}

.income_info .input-group-text {
  border: 1px solid transparent;
  padding: 0 22px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  border-left: none !important;
  min-width: 80px;
}
.income_info .input-group-text img {
  box-shadow: 0 16px 28px 0 rgba(223, 143, 240, 0.2);
  background: radial-gradient(
    135% 135% at 51.4% -7%,
    rgb(100, 8, 194) 0%,
    rgb(212, 81, 255) 100%
  );
  border-radius: 50px;
  padding: 15px;
}
.search_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom_placeholder::placeholder {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.02em;
  color: #fff;
}

.income_info .black-box1 p {
  font-weight: 700;
  font-size: 18px;
  line-height: 133%;
  color: #fcfbff;
}

.income_info .black-box1 h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #e396f3;
}
.income_info .black-box {
  background: none !important;
  padding: 30px 0px !important;
  max-width: 90%;
}
.progress-content {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-bottom: 15px;
}
.progress-content p {
  margin: 0;
  color: #ffffff;
}
.progress {
  background: #3c287d;
}
.progress-bar {
  background-color: #a14eda;
}
.tree {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin-top: -50px;
  margin-bottom: 30px;
}
.tree ul {
  margin: 0;
  padding: 0;
  padding-top: 30px;
  position: relative;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.tree li {
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 30px 20px 0 20px;
  transition: all 0.5s;
}
.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 13px;
  right: 50%;
  border-top: 2px solid #e097f3;
  width: 50%;
  height: 30px;
}
.tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px solid #e097f3;
  top: 13px;
}
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}
.tree li:only-child {
  padding-top: 0;
}
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}
.tree li:last-child::before {
  border-right: 2px solid #e097f3;
  border-radius: 0 2px 0 0;
}
.tree li:first-child::after {
  border-radius: 2px 0 0 0;
}
.tree ul ul::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 50%;
  border-left: 2px solid #e097f3;
  width: 0;
  height: 40px;
}
.tree li a {
  padding: 5px 10px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  transition: all 0.5s;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  z-index: 10;
  position: relative;
  top: 10px;
  background: #6408c2;
}

.react-tooltip {
  background-color: #e097f3 !important;
}

.tree li ul.second_chat li a {
  width: 60px;
  height: 60px;
}
.tree li ul.third_chat li a {
  width: 40px;
  height: 40px;
}
.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before {
  border-color: #e097f3;
}
.tree .root_img img {
  padding: 25px;
}

.tree .second_chat img {
  border-radius: 100%;
  background-color: #e097f3;
  box-shadow: 0 13px 40px -15px #e097f3;
  padding: 20px;
}
.tree .third_chat img {
  background-color: #f6d6ff;
  border-radius: 100%;
  box-shadow: 0 13px 40px -15px #e097f3;
  padding: 10px;
}

.dash_board_table .rdt_Table {
  background-color: transparent !important;
  color: #fff !important;
}
.dash_board_table .rdt_TableHeadRow {
  background-color: transparent !important;
  font-weight: 500;
  font-size: 16px;
  color: #e397f3 !important;
  border-bottom: 1px solid rgba(224, 151, 243, 0.15) !important;
  text-align: center;
  /* justify-content: center; */
}
/* .dash_board_table .rdt_TableCol_Sortable {
  justify-content: center;
} */
/* .dash_board_table .rdt_TableCell{
  justify-content: center;
}  */

.dash_board_table .rdt_TableRow {
  background-color: transparent !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  text-align: center;
  color: #fff !important;
  border-bottom: 1px solid rgba(224, 151, 243, 0.15) !important;
  /* justify-content: center; */
}
.dash_board_table .rdt_TableRow:last-child {
  border-bottom: 1px solid transparent !important;
}

.table_content {
  padding-top: 30px;
}

.table_content h3 {
  padding-left: 20px;
}
.rdt_TableCell {
  padding: 22px 30px !important;
}
.rdt_TableCol {
  padding: 22px 30px !important;
}
/* .rdt_TableCell:last-child {
  padding: 0px !important;
} */

.dash_board_table .rdt_TableRow:hover {
  background: linear-gradient(
    166deg,
    #e498f4 7.28%,
    #e094f3 23.78%,
    #7016c7 55.35%,
    #6408c2 87.59%
  );
  border-radius: 5px;
}
.referal_link {
  display: block;
  gap: 20px;
  padding: 10px 22px;
}

.referal_link .form-control {
  border-radius: 10px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid transparent;
  border-right: 1px solid transparent !important;
  color: #fff;
}

.referal_link .form-control:focus {
  box-shadow: none;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.1);
}
.referal_link .input-group-text img {
  cursor: pointer;
}
.referal_link .input-group-text {
  border: 1px solid transparent;
  padding: 0px;
  background: rgba(255, 255, 255, 0.1);
  border-left: none !important;
}
.referal_link .input-group-text img {
  padding: 15px;
}

.referal_link .footer_panel_right-ul li a {
  width: 35px;
  height: 35px;
}

@media (max-width: 1399px) {
  .withdraw_info .dashboard_info {
    flex-direction: column;
  }
  .package_info {
    justify-content: center;
  }
  .footer_panel_right-ul {
    gap: 10px;
    flex-wrap: wrap;
  }
}

@media (max-width: 991px) {
  .dashborad_content {
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
  }
  .tree {
    margin-top: -11px;
  }
  .dashboard_info {
    border-right: 1px solid transparent;
  }
  .dashborad_content .dashboard_info:nth-child(3) {
    margin-top: 0px;
  }
  .dashborad_content .dashboard_info:nth-child(4) {
    border-left: none;
    padding-left: 0px;
  }
  .dashboard_info {
    padding-right: 0px;
    width: 100%;
  }
  .btn_mode .dropdown {
    width: 100%;
  }
  #dropdownMenuLink {
    width: 100%;
    display: block;
  }
  .btn_mode .primary_btn {
    width: 100%;
  }
  .offcanvas-header .btn-close {
    filter: invert(1);
  }
}

@media (max-width: 767px) {
  .package_info .joined {
    width: 100%;
    display: block;
  }
  .package_info .re_join {
    width: 100%;
    display: block;
  }
  .package_info .disabled {
    width: 100%;
    display: block;
  }
  .income_info .black-box {
    max-width: 100% !important;
  }
  .dashboard_head {
    flex-direction: column;
  }
  .dashborad_content .dashboard_info:nth-child(4) {
    border-left: none;
    padding-left: 0px;
  }
  .dashboard_info {
    padding-right: 0px;
    width: 100%;
    padding: 0px;
  }
  .dashborad_content {
    gap: 10px;
  }

  .black-box1 p {
    text-align: left;
  }
}
@media (max-width: 575px) {
  .package_info .joined {
    width: 100%;
    display: block;
  }
  .dashboard_section {
    padding: 15px;
  }
  .package_info .dashboard_info {
    padding: 18px 87px;
  }
  .package_info .joined::after {
    content: "Joined";
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #130042;
    padding: 2px 22px;
    border-radius: 0 0px 25px 25px;
    display: inline-block;
    transform: rotate(-90deg);
    position: absolute;
    right: -43px;
    top: 38px;
  }
  .package_info .dashboard_info h3 {
    font-weight: 500;
    font-size: 28px;

    margin-bottom: 8px;
  }
  .package_info .dashboard_info p {
    font-weight: 500;
    font-size: 14px;
  }
  .package_info .joined::after {
    content: "Joined";
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #130042;
    padding: 2px 21px;
    border-radius: 0 0px 25px 25px;
    display: inline-block;
    transform: rotate(-90deg);
    position: absolute;
    right: -34px;
    top: 34px;
  }
  .package_info .re_join {
    padding: 18px 87px;
  }
  .search_bar {
    flex-direction: column;
    gap: 10px;
  }
  .income_info .black-box1 h2 {
    font-size: 40px;
  }
  .black-box1 {
    flex-direction: row;
    gap: 2px;
  }
  .income_info .black-box1 p {
    font-size: 16px;
  }
  .register_time p {
    font-size: 14px;
  }
  .tree li {
    padding: 30px 10px 0 10px;
  }
  .ptag1 {
    word-break: break-all;
  }
  .default-address {
    word-break: break-all;
  }
  .joinnow-btn.select::before {
    left: 10px !important;
  }
  .joinnow-btn {
    width: 100% !important;
  }
}
/* =-------------------------------------light theme----------------------------------------- */
.light .rdt_Pagination {
  color: #6408c2;
}
.light .progress-content p {
  color: #a14eda;
}
.light .progress {
  background: #e19afd;
}
.light .rdt_Pagination button {
  color: #6408c2 !important;
  fill: #6408c2 !important;
}
body.light {
  background-color: #fff;
}
.light .walletdrop.show {
  background: #e3aafa !important;
}
.light .walletdrop .dropdown-item {
  color: #130042;
}
.light .react-tooltip {
  background-color: rgb(100, 8, 194) !important;
}
.light .dropdown-item:focus,
.dropdown-item:hover {
  background-color: #dd97fc;
  color: rgb(255, 255, 255);
}
.light .head1::before {
  display: none;
}
.light .page_header h1 {
  color: #130043;
}
.light .page_header .para {
  color: #140043;
}
.light .page_header .side::before {
  display: none;
}
.light .sec2-left::before {
  display: none;
}
.light .ban2-sec p {
  color: #140043;
}
.light .ban2-sec h2 {
  color: #130042;
}
.light .ban2-sec h6 {
  color: #6408c2;
}
.light .sec2-card {
  border-bottom: 1px solid #eba6fb !important;
  border-right: 1px solid #eba6fb !important;
  border-top: 1px solid #eba6fb !important;
  border-radius: 25px !important;
  background: #f9dfff !important;
}
.light .sec2-card-content p {
  color: #6408c2 !important;
}
.light .sec3-bg {
  background: url(./assets/images/sec3-banner-light.png);
}
.light .black-box {
  border-bottom: 1px solid #de89f1;
  border-right: 1px solid #de89f1;
  border-top: 1px solid #de89f1;
  border-radius: 25px;
  background: radial-gradient(
    65.27% 114.93% at 49.85% -15.06%,
    rgb(249, 223, 255) 0%,
    rgb(242, 198, 252) 100%
  );
}
.light .black-box1 p {
  color: #6508c2;
}
.light .black-box1 h2 {
  color: #160245;
}
.light .faq .head h2 {
  color: #130042;
}
.light .faq .accordion-item button {
  color: #6508c2;
}
.light .faq .accordion-button:not(.collapsed) {
  color: #6508c2;
}
.light .faq .para {
  color: #130042;
}
.light .faq .accordion-item {
  border-bottom: 1px solid #5148a1;
}
.light .faq .accordion-button.collapsed::after {
  background: url(./assets/images/down_arw_light.png);
  width: 12px;
}
.light .faq .accordion-button:not(.collapsed)::after {
  background: url(./assets/images/down_arw_light.png);
  width: 12px;
}
.light .black-box1 hr {
  border-color: #de89f1;
}
.light .register_time {
  border: none;
  background: linear-gradient(
    166deg,
    #e498f4 7.28%,
    #e094f3 23.78%,
    #7016c7 55.35%,
    #6408c2 87.59%
  );
}
.light .dashboard_head h3 {
  color: #130042;
}
.light .register_time p {
  color: #fff;
}
.light .dashborad_content {
  border-bottom: 1px solid #eba6fb;
  border-right: 1px solid #eba6fb;
  border-top: 1px solid #eba6fb;
  border-radius: 25px;
  background: radial-gradient(
    circle,
    rgba(245, 210, 253, 1) 0%,
    rgba(224, 152, 253, 1) 100%
  );
}
.light .dashboard_info h3 {
  color: #130042;
}
.light .dashboard_info h3 span {
  color: #130042;
}
.light .dashboard_info p {
  color: #6408c2;
}
.light .income_info {
  background: radial-gradient(
    65.27% 114.93% at 49.85% -15.06%,
    rgb(249, 223, 255) 0%,
    rgb(242, 198, 252) 100%
  );
  border: none;
}
.light .income_info h3 {
  color: #130042;
}
.light .package_info .dashboard_info {
  border-bottom: 1px solid #eba6fb;
  border-right: 1px solid #eba6fb;
  border-top: 1px solid #eba6fb;
  border-radius: 25px;
  background: radial-gradient(
    65.27% 114.93% at 49.85% -15.06%,
    rgb(249, 223, 255) 0%,
    rgb(242, 198, 252) 100%
  );
}
.light .missed_income.income_info .dashboard_info {
  border-bottom: 1px solid #f8c808;
  border-right: 1px solid #f8c808;
  border-top: 1px solid #f8c809;
  border-radius: 15px;
  background: #f7d23f;
}
.light .package_info .joined::after {
  box-shadow: 0 16px 28px 0 rgba(223, 143, 240, 0.2);
  background: radial-gradient(
    135% 135% at 51.4% -7%,
    rgb(100, 8, 194) 0%,
    rgb(214, 137, 239) 100%
  );
  color: #fff;
}
.light .dashboard_info {
  border-right: 1px solid transparent;
}
.light .dashborad_content .dashboard_info:nth-child(4) {
  border-left: 1px solid transparent;
}
.light .custom_placeholder::placeholder {
  color: #6508c2;
}
.light .input-group {
  background: #fff;
  border-radius: 25px;
  border-bottom: 1px solid #de89f1;
  border-right: 1px solid #de89f1;
  border-top: 1px solid #de89f1;
}
.light .income_info .form-control {
  color: #6508c2;
}
.light .referal_link .form-control {
  color: #6508c2;
}
.light .income_info .black-box {
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
}
.light .dash_board_table .rdt_TableHeadRow {
  color: #130042 !important;
}
.light .dash_board_table .rdt_TableRow {
  color: #6408c2 !important;
}
.light .dash_board_table .rdt_TableRow:hover {
  color: #fff !important;
}
.light .rank_board {
  border-bottom: 1px solid #eba6fb;
  border-right: 1px solid #eba6fb;
  border-top: 1px solid #eba6fb;
  background: #f9dfff;
}
.light .rank_board h6 {
  color: #130042;
}
.light .rank_board h4 {
  color: #130042;
}
.light .copy-img {
  filter: invert(0);
}
.light .withdraw_info .dashboard_info {
  border-bottom: 1px solid #eba6fb;
  border-right: 1px solid #eba6fb;
  border-top: 1px solid #eba6fb;
  border-radius: 15px;
  background: #f9dfff;
}
.light .withdraw_info {
  background: #f9dfff !important;
  border-bottom: 1px solid #eba6fb;
  border-right: 1px solid #eba6fb;
  border-top: 1px solid #eba6fb;
}
.light .text-Referral {
  border-bottom: 1px solid #eba6fb;
  border-right: 1px solid #eba6fb;
  border-top: 1px solid #eba6fb;
  border-radius: 15px;
  background: #f9dfff;
}
.light .footer_panel_right-ul_1 li a {
  box-shadow: 0 16px 28px 0 rgba(223, 143, 240, 0.2);
  background: radial-gradient(
    135% 135% at 51.4% -7%,
    rgb(100, 8, 194) 0%,
    rgb(214, 137, 239) 100%
  );
  color: #fff !important;
}
.light .footer_panel_right-ul_1 li a:hover {
  background: radial-gradient(
    135% 135% at 51.4% -7%,
    rgb(214, 137, 239) 0%,
    rgb(100, 8, 194) 100%
  );
}
.light .navbar-brand::after {
  content: "";
  background-color: rgb(100 8 194 / 14%);
  position: absolute;
  top: 35px;
  bottom: 0;
  left: -0px;
  right: 83%;
  z-index: -1;
  border-radius: 50%;
  filter: blur(59px);
}
.light .navbar-toggler-icon {
  filter: invert(1);
}
.light .navbar-toggler {
  border: 1px solid #b9b0b0;
}
.light .dashborad_content::after {
  content: "";
  background-color: rgb(100 8 194 / 14%);
  position: absolute;
  top: 35px;
  bottom: 0;
  left: -0px;
  right: 83%;
  z-index: -1;
  filter: blur(295px);
}
.light .sec3-bg h2 {
  color: #130043;
}
.light .sec3-bg p {
  color: #6408c2;
}
.light .withdrow_bg::after {
  content: "";
  background-color: rgb(100 8 194 / 14%);
  position: absolute;
  top: 35px;
  bottom: 0;
  left: -0px;
  right: 83%;
  z-index: -1;
  filter: blur(295px);
}
.light .bg-sec {
  position: relative;
  overflow: hidden;
}
.light .bg-sec::before {
  content: "";
  background: url(./assets/images/ban_before1.png) no-repeat right;
  background-size: 100%;
  width: 874px;
  height: 357px;
  position: absolute;
  left: 461px;
  right: 0px;
  top: 164px;
  margin: auto;
  z-index: -1;
}
.light .bg-sec1 {
  position: relative;
}
.light .bg-sec1::before {
  content: "";
  background-color: rgb(100 8 194 / 20%);
  position: absolute;
  top: 35px;
  bottom: 0;
  left: -0px;
  right: 83%;
  z-index: -1;
  filter: blur(295px);
}
.light .withdrow_bg {
  position: relative;
  overflow: hidden;
}
.light .withdrow_bg::before {
  content: "";
  background: url(./assets/images/ban_before2.png) no-repeat right;
  background-size: 100%;
  width: 874px;
  height: 357px;
  position: absolute;
  left: -136px;
  right: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
}
.light .primary_modal .modal-content {
  border-bottom: 1px solid #eba6fb !important;
  border-right: 1px solid #eba6fb !important;
  border-top: 1px solid #eba6fb !important;
  background: #f0b1ff !important;
}
.light .primary_modal .modal-header h5 {
  color: #130043;
}
.light .connect_wallet_button span {
  color: #130043;
}
.light .modal-header .btn-close {
  filter: invert(0);
}
.light .dark-img {
  display: none;
}
.light .light-img {
  display: none;
}
.dark .dark1-img {
  display: none;
}
.dark .light1-img {
  display: none;
}
.light .modechange {
  border-bottom: 1px solid #eba6fb !important;
  border-right: 1px solid #eba6fb !important;
  border-top: 1px solid #eba6fb !important;
  border-radius: 25px !important;
  background: #f0b1ff !important;
}

.loadBefore::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgb(10 10 10 / 75%);
  z-index: 1500;
  overflow: hidden;
}
.light .navbar-sticky--moved-up {
  background: white;
}
.light .default-address {
  background: rgba(105, 13, 196, 0.1);
  padding: 12px;
  border-radius: 10px;
  color: #6a0fc5;
}
.light .joinnow .box {
  border-bottom: 1px solid #eba6fb;
  border-right: 1px solid #eba6fb;
  border-top: 1px solid #eba6fb;
  border-left: 1px solid #eba6fb;
  border-radius: 25px;
  background: radial-gradient(
    circle,
    rgba(245, 210, 253, 1) 0%,
    rgba(224, 152, 253, 1) 100%
  );
}
.light .h2tag {
  color: #130042;
}
.light .joinnow .box input {
  color: #6508c2 !important;
}
.light .joinnow .box input::placeholder {
  color: #6508c2;
}
.light .box label {
  color: #6a0fc5;
}
.light .popupPara {
  color: #6408c2;
}
#Rejoin_popUp .table thead,
#Rejoin_popUp .table tr,
#Rejoin_popUp .table th,
#Rejoin_popUp .table td {
  background: transparent !important;
  color: #fff !important;
  border-color: rgba(224, 151, 243, 0.15) !important;
}
.light #Rejoin_popUp .table thead,
.light #Rejoin_popUp .table tr,
.light #Rejoin_popUp .table th,
.light #Rejoin_popUp .table td {
  background: transparent !important;
  color: #6408c2 !important;
  border-color: #e097f3 !important;
}
.light .rdt_Table > div {
  color: #130042 !important;
}
.light .rdt_Pagination {
  color: #6408c2 !important;
}
.light .offcanvas-header .btn-close {
  filter: invert(0);
}
.withdraw_info .Achieved_1 {
  background-color: red !important;
}
.text_box_1 .form-control {
  border: 1px solid transparent !important;
}
.text_box_1 .input-group-text {
  border: 1px solid transparent !important;
}
.text_box_1.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
}
.join-now-sides {
  display: flex;
  margin-bottom: 20px;
}
.right-side-joinnow {
  padding: 0px 20px;
  width: 50%;
}
.left-side-joinnow {
  width: 50%;
}
.joinnow-btn-head {
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}
.joinnow-btn {
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  border: 1px solid transparent;

  background: rgba(105, 13, 196, 0.1);
  color: #6a0fc5;
  width: 45%;
}
.joinnow-btn:hover {
  color: #fff;
  background: #6a0fc5;
}
.joinnow-btn.select {
  position: relative;
  color: #fff;
  background: #6a0fc5;
}
.joinnow-btn.select::before {
  content: "";
  background: url(./assets/images/check-circle1.png);
  width: 24px;
  height: 24px;
  position: absolute;
  left: 20px;
}

.joinnow-btn.select:hover::before {
  background: url(./assets/images/check-circle1.png);
}
